export type statusStylesType = Record<string, Record<string, string>>;

export enum NOTIFICATION_TYPES {
    ORDER_RECEIVED = 'OrderReceived',
    PAYMENT_RECEIVED = 'PaymentReceived',
}

export interface UrlData {
    cc: string;
    f1: string;
    f2: string;
    slug: string;
    tableId: string;
}

export interface Item {
    id: number;
    price: number;
    quantity: number;
}

export interface OrderData {
    extra?: any;
    items: Item[];
}

export interface OrderStatus {
    id: string;
    name: string;
    created_at: string;
    updated_at: string;
    deleted_at?: any;
}

export interface IOrderVendorId {
    cc: string;
    slug: string;
}

export interface IOrder {
    items: IOrderItem[];
    extra: any;
    note?: string;
    totalAmount?: number;
    passcode?: string;
    vendorId: IOrderVendorId;
    version?: 'v2';
}

export enum OrderItemStatusEnum {
    New = 'new',
    Approved = 'approved',
    Completed = 'completed',
    Rejected = 'rejected',
    Scheduled = 'scheduled',
}

export interface IOrderItem extends IAdditiveItem {
    selectionId?: number;
    menuId?: string;
    uid?: string;
    timestamp?: number;
    status?: OrderItemStatusEnum;
    note?: string;
    batchNote?: string;
}

export interface IAdditiveItem {
    id: string;
    quantity: number;
    price: number;
    mgId?: string;
    optionId?: string;
    additives?: IAdditiveItem[];
}

export enum OrderStatusEnum {
    Pending = 'pending',
    Cancelled = 'cancelled',
    Active = 'active',
    Closed = 'closed',
    Declined = 'declined',
    Approved = 'approved',
    Failed = 'failed',
    Scheduled = 'scheduled',
    PickUp = 'pickUp',
    SyncFailed = 'syncFailed',
}

export enum OrderPaymentStatusEnum {
    Pending = 'pending',
    Failed = 'failed',
    Success = 'success',
    Refunded = 'refunded',
}

export enum OrderPosStatusEnum {
    Pending = 'pending',
    Failed = 'failed',
    Success = 'success',
}

export interface IQsrPriceAdditive {
    association: string;
    key: string;
    title: string;
    value: string;
}

export interface IQsrUserInfoMeta {
    cpf?: string;
    carPlate?: string;
}

export interface IQsrUserInfo {
    name?: string;
    phone?: string;
    email?: string;
    address?: string;
    meta?: IQsrUserInfoMeta;
}

export enum DiningOptionEnum {
    DINING = 'DINE-IN',
    TAKEAWAY = 'TAKEAWAY',
    DRIVE_THROUGH = 'DRIVE-THROUGH',
}

export interface IOrderUrlData {
    cc: string;
    slug: string;
    tableId: string;
    f1: string;
    f2: string;
    hash?: string;
    name?: string;
}

export interface IOrderTableData {
    paxCount?: number;
}

export interface IQsrOrder {
    id: string;
    posVendor: string;
    refId: string;
    orderData: IOrder;
    additives?: IQsrPriceAdditive[];
    orderStatus?: { name: OrderStatusEnum };
    ticketId: any;
    url: string;
    urlData: IOrderUrlData;
    tableData?: IOrderTableData;
    message: string;
    customerComment: any;
    paymentStatus: OrderPaymentStatusEnum;
    posStatus: OrderPosStatusEnum;
    paymentResponse?: any;
    paidAt?: string;
    createdBy?: any;
    createdByTemp?: any;
    total: number;
    subTotal: number;
    createdAt: Date | string;
    updatedAt: string;
    statusId: string;
    userInfo?: IQsrUserInfo;
    force?: boolean;
    silent?: boolean;
    skipPrint?: boolean;
    totalStr: string;
    subTotalStr?: string;
    date?: Date | string;
    batchStatus?: OrderItemStatusEnum;
    batchId?: string;
    batchSort?: boolean;
    note?: string;
    pickupAt?: Date | string;
    scheduledAt?: Date | string;
    diningOption?: DiningOptionEnum;
    panelUserId?: string | null;
    panelDeviceId?: string | null;
    panelUpdatedAt?: Date | string;
}

export interface IQsrOrdersRequestParams {
    restaurantId: string;
    page: number;
    limit: number;
    status?: OrderStatusEnum;
    statuses?: OrderStatusEnum[];
    notInStatuses?: OrderStatusEnum[];
    paymentStatuses?: OrderPaymentStatusEnum[];
    posStatuses?: OrderPosStatusEnum[];
    startDate?: string | null;
    endDate?: string | null;
    qrIds?: string;
    refId?: string;
}

export interface IPagination {
    count: number;
    limit: string;
    page: string;
    offset: number;
}

export interface IQsrOrdersResponse {
    orders: IQsrOrder[];
    pagination: IPagination;
    vendorDetails: {
        currency_code: string;
        currency_symbol: string;
    };
}

export interface IQsrOrderByIdResponse {
    order: IQsrOrder;
}

export interface IQsrOrderSetStatusRequestParams {
    restaurantId: string;
    orderId: string;
    status: string;
    ticketId?: string;
    reason_id?: string;
    reason_message?: string;
}

export interface IQsrOrderSetPrintParams {
    restaurantId: string;
    orderId: string;
}

export interface IQsrOrderItemSetStatusRequestParams {
    restaurantId: string;
    orderId: string;
    uid: string;
    status: OrderItemStatusEnum;
}

export interface IQsrProductParams {
    ids: string[];
}

export interface IQsrCategoryParams {
    ids: string[];
}

export interface IQsrPanelUserParams {
    ids: string[];
}

export interface IQsrOrderNotification extends IQsrOrder {
    date?: string;
}
