import { ITransaction } from '@/views/Payments';
import { ITableParams } from '@/views/OrdersTableView/types';
import { IQsrOrder } from '@/views/QsrOrders/types';

export const isTableMatch = (params: ITableParams, id: string, f1?: string, f2?: string): boolean => {
    const validId = id === params.id;
    const validF1 = !f1 || f1 === params.f1;
    const validF2 = !f2 || f2 === params.f2;
    return validId && validF1 && validF2;
};

export const isTableTransactionMatch = (params: ITableParams, transaction: ITransaction | IQsrOrder): boolean => {
    return isTableMatch(
        params,
        'table_id' in transaction ? transaction.table_id : '',
        // transaction.table_id !== transaction?.table_data?.id ? transaction.table_data.id?.split('_')?.[0] : undefined,
        // transaction?.table_data?.section,
    );
};

export const isTableParamMatch = (params: ITableParams, params2: ITableParams): boolean => {
    return isTableMatch(params, params2.id || '', params2.f1, params2.f2);
};
