import axios from '@/config/axios';
import { transformError } from '@/services';
import { IPrinterConfig } from '@/views/OrdersTableView/types';
import { IParams, PrinterTypeEnum } from '@/interfaces/printer';

class PrinterService {
    public static getInstance() {
        if (!this.instance) {
            this.instance = new PrinterService();
        }

        return this.instance;
    }

    private static instance: PrinterService;

    public getPrinterList(restaurantId: string, type: PrinterTypeEnum) {
        return axios
            .get(`/vendor/restaurant/${restaurantId}/printer/${type}/list`)
            .then((res) => res.data)
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    // public getPrinterById(restaurantId: string, printerId: string) {
    //     return axios
    //         .get(`/admin/restaurant/${restaurantId}/printer/${printerId}`)
    //         .then((res) => res.data)
    //         .then((res) => res.data)
    //         .catch((err) => {
    //             throw transformError(err);
    //         });
    // }
    //
    // public updatePrinterStatus(restaurantId: string, printerId: string, isActive: boolean) {
    //     return axios
    //         .put(`/admin/restaurant/${restaurantId}/printer/status/update/${printerId}`, { isActive })
    //         .then((res) => res.data)
    //         .catch((err) => {
    //             throw transformError(err);
    //         });
    // }

    public updatePrinterConfig(restaurantId: string, printerId: string, data: IPrinterConfig) {
        return axios
            .put(`/vendor/restaurant/${restaurantId}/printer/config/${printerId}`, data)
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public downloadPrint(restaurantId: string, key: string) {
        return axios
            .post(`/vendor/restaurant/${restaurantId}/printer/history/download`, { key }, { responseType: 'blob' })
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public printRetry(restaurantId: string, id: string) {
        return axios
            .post(`/vendor/restaurant/${restaurantId}/printer/history/retry/${id}`)
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public getPrintHistoryList(restaurantId: string, params: IParams) {
        return axios
            .get(`/vendor/restaurant/${restaurantId}/printer/history`, {
                params: {
                    page: params.page,
                    limit: params.limit,
                    currentStatus: params.currentStatus,
                    printId: params.printId,
                    printerType: params.printerType,
                    printType: params.printType,
                },
            })
            .then((res) => res.data)
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }
}

export default PrinterService;
