import { getCurrencyWithAmount } from '@/common/utility';
import { CenterFocusStrong, CenterFocusWeak, VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { TableCell, Box, Typography, IconButton, styled, TableRow } from '@mui/material';

interface RestaurantRowProps {
    data: any;
    name: string;
    color: string;
    currencySymbol: string;
    currencyCode: string;
    showTipCard: boolean;
    selected: boolean;
    setSelectedRestaurants: React.Dispatch<React.SetStateAction<string[]>>;
    focused: boolean;
    setFocusedRestaurant: any;
    otherFocused?: boolean;
    restaurants: string[];
    hasAnyRefund: boolean;
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
    },
}));
export const RestaurantRow = ({
    data,
    name,
    color,
    currencySymbol,
    currencyCode,
    showTipCard,
    selected,
    setSelectedRestaurants,
    focused,
    setFocusedRestaurant,
    otherFocused,
    restaurants,
    hasAnyRefund,
}: RestaurantRowProps) => {
    const totalRevenue = data.reduce((a: number, b: any) => a + b.revenue, 0.0);
    const totalRevenueWithTip = data.reduce((a: number, b: any) => a + b.revenue_with_tip, 0.0);
    const totalTip = totalRevenueWithTip - totalRevenue;
    const totalRefunds = hasAnyRefund ? data.reduce((a: number, b: any) => a + b.refunds, 0.0) : 0.0;

    const disableIcons = otherFocused && !focused;

    return (
        <StyledTableRow>
            <TableCell sx={{ borderBottom: 'none', opacity: selected ? 1 : 0.5 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box
                        sx={{
                            width: '1.5rem',
                            height: '1.5rem',
                            borderRadius: '0.3rem',
                            backgroundColor: color,
                            marginRight: '1rem',
                        }}
                    />
                    <Typography>{name}</Typography>
                </Box>
            </TableCell>
            <TableCell sx={{ borderBottom: 'none', opacity: selected ? 1 : 0.5 }}>
                {getCurrencyWithAmount(totalRevenue, currencySymbol, currencyCode, true)}
            </TableCell>
            {showTipCard && (
                <TableCell sx={{ borderBottom: 'none', opacity: selected ? 1 : 0.5 }}>
                    {getCurrencyWithAmount(totalTip, currencySymbol, currencyCode, true)}
                </TableCell>
            )}
            {hasAnyRefund && (
                <TableCell sx={{ borderBottom: 'none', opacity: selected ? 1 : 0.5 }}>
                    {getCurrencyWithAmount(totalRefunds, currencySymbol, currencyCode, true)}
                </TableCell>
            )}
            <TableCell sx={{ borderBottom: 'none' }}>
                {focused ? (
                    <IconButton
                        onClick={() => {
                            setFocusedRestaurant('');
                            setSelectedRestaurants(restaurants);
                        }}
                        disabled={disableIcons}
                    >
                        <CenterFocusStrong />
                    </IconButton>
                ) : (
                    <IconButton
                        onClick={() => {
                            setFocusedRestaurant(name);
                            setSelectedRestaurants((prev: string[]) => {
                                return prev.includes(name) ? prev.filter((n) => n === name) : [...prev, name];
                            });
                        }}
                        disabled={disableIcons}
                    >
                        <CenterFocusWeak />
                    </IconButton>
                )}
                {selected ? (
                    <IconButton
                        onClick={() =>
                            setSelectedRestaurants((prev: string[]) => {
                                return prev.includes(name) ? prev.filter((n) => n !== name) : [...prev, name];
                            })
                        }
                        disabled={disableIcons || focused}
                    >
                        <VisibilityOutlined />
                    </IconButton>
                ) : (
                    <IconButton
                        onClick={() =>
                            setSelectedRestaurants((prev: string[]) => {
                                return prev.includes(name) ? prev.filter((n) => n !== name) : [...prev, name];
                            })
                        }
                        disabled={disableIcons || focused}
                    >
                        <VisibilityOffOutlined />
                    </IconButton>
                )}
            </TableCell>
        </StyledTableRow>
    );
};
