import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import QrGridView from '@/views/QRCode/views/Grid';
import { useSnackbar } from 'notistack';
import { useRestaurantContext } from '@/contexts/restaurant';
import RestaurantService from '@/services/restaurant';
import { IRestaurantTables } from '@/views/OrdersTableView/types';
import { Button, Drawer, IconButton } from '@mui/material';
import { ChevronLeftRounded, CloseRounded } from '@mui/icons-material';
import { IQR } from '@/views/QRCode/types';
import { useTranslation } from '@/hooks/translations';
import Slide from '@mui/material/Slide';
import QsrPaxCount from '@/components/QsrPaxCount';

import classNames from 'classnames';
import styles from './index.module.scss';

export interface IQsrTable {
    qr: IRestaurantTables;
    guestCount: number;
}

enum StepEnum {
    SelectTable = 'selectTable',
    TableInfo = 'tableInfo',
    Done = 'done',
}

interface IPromise {
    resolve: any;
    reject: any;
}

export interface IQsrModalFunctions {
    open: () => Promise<IQsrTable>;
}

interface IProps {
    withPaxCount?: boolean;
}

const QsrQRModal = forwardRef<IQsrModalFunctions, IProps>(({ withPaxCount }, ref) => {
    const restaurantService = RestaurantService.getInstance();
    const { t } = useTranslation('common');
    const { enqueueSnackbar } = useSnackbar();
    const { restaurant } = useRestaurantContext();
    const containerRef = useRef<any>(null);
    const promiseRef = useRef<IPromise | null>(null);
    const [open, setOpen] = useState(false);
    const [step, setStep] = useState<StepEnum>(StepEnum.SelectTable);
    const [qrList, setQrList] = useState<IRestaurantTables[]>([]);
    const [guestCount, setGuestCount] = useState(0);
    const [selectedQr, setSelectedQr] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState(false);

    const enableTableInfo = restaurant?.order_config?.qrTableDataEnable || withPaxCount || false;

    const getQrs = useCallback(() => {
        if (!restaurant?.id) {
            return;
        }

        restaurantService
            .getQrs(restaurant.id)
            .then((res) => {
                setQrList(res.rows || []);
            })
            .catch((err) => {
                enqueueSnackbar(err.message, {
                    variant: 'error',
                });
            })
            .finally(() => {
                setLoading(false);
            });
    }, [restaurant?.id || '']);

    useEffect(() => {
        if (!restaurant?.id) {
            return;
        }

        getQrs();
    }, [restaurant?.id || '']);

    const moveToStepHandler = useCallback(
        (s: StepEnum) => () => {
            setStep(s);
        },
        [],
    );

    const openModal = useCallback(() => {
        return new Promise<IQsrTable>((resolve, reject) => {
            promiseRef.current = {
                resolve,
                reject,
            };
            setStep(StepEnum.SelectTable);
            setOpen(true);
        });
    }, []);

    const closeHandler = useCallback(() => {
        setOpen(false);
        setStep(StepEnum.SelectTable);
        setSelectedQr(undefined);
    }, []);

    const selectQrHandler = useCallback(
        (item: IQR | IRestaurantTables) => {
            if (selectedQr === item.id) {
                setSelectedQr(undefined);
            } else {
                setSelectedQr(item.id);
                if (enableTableInfo) {
                    setStep(StepEnum.TableInfo);
                }
            }
        },
        [selectedQr, enableTableInfo],
    );

    useImperativeHandle(
        ref,
        () => {
            return {
                open: openModal,
            };
        },
        [openModal],
    );

    const doneHandler = useCallback(() => {
        if (!promiseRef.current) {
            return;
        }

        const qr = qrList.find((o) => o.id === selectedQr);
        if (!qr) {
            return;
        }

        closeHandler();

        promiseRef.current.resolve({
            qr,
            guestCount,
        });
    }, [qrList, selectedQr, guestCount]);

    const nextHandler = useCallback(() => {
        switch (step) {
            case StepEnum.SelectTable:
                if (enableTableInfo) {
                    setSelectedQr(StepEnum.TableInfo);
                } else {
                    doneHandler();
                }
                break;
            case StepEnum.TableInfo:
                doneHandler();
                break;
            default:
                break;
        }
    }, [step, enableTableInfo, doneHandler]);

    const modalHeader = useMemo(() => {
        switch (step) {
            case StepEnum.SelectTable:
                return (
                    <>
                        <div className={styles.title}>{t('Select a table')}</div>
                        <IconButton onClick={closeHandler}>
                            <CloseRounded />
                        </IconButton>
                    </>
                );
            case StepEnum.TableInfo:
                return (
                    <>
                        <IconButton onClick={moveToStepHandler(StepEnum.SelectTable)}>
                            <ChevronLeftRounded />
                        </IconButton>
                        <div className={styles.title}>{t('Guest information')}</div>
                        <IconButton onClick={closeHandler}>
                            <CloseRounded />
                        </IconButton>
                    </>
                );
            default:
                return null;
        }
    }, [step]);

    const modalFooter = useMemo(() => {
        switch (step) {
            case StepEnum.SelectTable:
                return (
                    <Button variant="contained" size="large" disabled={!selectedQr} onClick={nextHandler}>
                        {t('next')}
                    </Button>
                );
            case StepEnum.TableInfo:
                return (
                    <Button variant="contained" size="large" disabled={guestCount === 0} onClick={nextHandler}>
                        {t('next')}
                    </Button>
                );
            default:
                return null;
        }
    }, [step, selectedQr, guestCount, nextHandler]);

    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={closeHandler}
            keepMounted
            PaperProps={{
                sx: {
                    overflowY: 'hidden',
                },
            }}
        >
            <div className={styles.container}>
                <div className={styles.header}>{modalHeader}</div>
                <div className={styles.content} ref={containerRef}>
                    <div className={styles.page}>
                        <QrGridView
                            qrList={qrList}
                            embed
                            selectable
                            selectedId={selectedQr}
                            loading={loading}
                            onClick={selectQrHandler}
                        />
                    </div>
                    {enableTableInfo && (
                        <Slide
                            direction="right"
                            in={step === StepEnum.TableInfo}
                            mountOnEnter
                            unmountOnExit
                            container={containerRef.current}
                        >
                            <div className={classNames(styles.page, styles.overlay)}>
                                <QsrPaxCount
                                    onChange={(v) => {
                                        setGuestCount(v);
                                    }}
                                />
                            </div>
                        </Slide>
                    )}
                </div>
                <div className={styles.footer}>{modalFooter}</div>
            </div>
        </Drawer>
    );
});

export default QsrQRModal;
