import { TableContent } from '@/components/common';
import { getCurrencyPrecision } from '@/services/utils/k_currency';
import { Grid, Box, Tabs, Tab, TableCell, Typography } from '@mui/material';
import { ResponsiveContainer, XAxis, YAxis, Tooltip, LineChart, Line, BarChart, Bar } from 'recharts';
import { useTranslation } from '@/hooks/translations';
import { TimeRangeTypes } from '@/components/TimeRange/Form/types';
import { useEffect, useState } from 'react';
import { RestaurantRow } from './RestaurantRow';

interface IData {
    date: string;
    revenue: number;
    revenue_with_tip: number;
    refunds: number;
}
interface IChartData {
    colors: string[];
    restaurants: string[];
    currencySymbol: string;
    currencyCode: string;
    data: IData[][];
    hasAnyRefund: boolean;
}

interface BrandChartProps {
    chartMode: string;
    chartData: IChartData;
    handleTabChange: (event: React.SyntheticEvent, newValue: 'revenue' | 'tip') => void;
    showTipCard: boolean;
    period: TimeRangeTypes;
}

export default function BrandChart({ chartMode, chartData, handleTabChange, showTipCard, period }: BrandChartProps) {
    const { t } = useTranslation('common');

    const [selectedRestaurants, setSelectedRestaurants] = useState<string[]>(chartData?.restaurants);
    const [filteredData, setFilteredData] = useState<IChartData>(chartData);
    const [focusedRestaurant, setFocusedRestaurant] = useState<string>('');

    useEffect(() => {
        const filtered = {
            colors: chartData.colors.filter((_, i) => selectedRestaurants.includes(chartData.restaurants[i])),
            restaurants: chartData.restaurants.filter((r) => selectedRestaurants.includes(r)),
            currencySymbol: chartData.currencySymbol,
            currencyCode: chartData.currencyCode,
            data: chartData.data.filter((_, i) => selectedRestaurants.includes(chartData.restaurants[i])),
            hasAnyRefund: chartData.data.some((d: any) => d.some((o: any) => o.refunds > 0)),
        };

        setFilteredData(filtered);
    }, [selectedRestaurants, chartData]);

    if (!chartData.data?.length) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <Typography>{t('No data available for selected period')}</Typography>
            </Box>
        );
    }

    return (
        <>
            <Grid item xs={12}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Tabs value={chartMode} onChange={handleTabChange} variant="fullWidth">
                        <Tab value="revenue" label={t('Revenue')} />
                        {showTipCard && <Tab value="tip" label={t('Tips')} />}
                    </Tabs>
                </Box>
                <Grid container>
                    <Grid item xs={12}>
                        {filteredData && (
                            <ResponsiveContainer width="99%" height={300}>
                                {filteredData?.data?.[0]?.length > 1 && !focusedRestaurant ? (
                                    <LineChart
                                        data={filteredData.data?.[0] || []}
                                        margin={{
                                            top: 40,
                                            right: 30,
                                            left: 0,
                                            bottom: 0,
                                        }}
                                    >
                                        <XAxis dataKey="date" />

                                        <YAxis
                                            domain={[
                                                0,
                                                chartMode === 'revenue'
                                                    ? Math.max(
                                                          ...(filteredData.data || []).flatMap((c: IData[]) =>
                                                              c.map((c1: IData) => Math.round(c1.revenue_with_tip)),
                                                          ),
                                                      )
                                                    : Math.max(
                                                          ...(filteredData.data || []).flatMap((c: IData[]) =>
                                                              c.map((c1: IData) =>
                                                                  parseFloat(
                                                                      (c1.revenue_with_tip - c1.revenue).toFixed(2),
                                                                  ),
                                                              ),
                                                          ),
                                                      ),
                                            ]}
                                        />
                                        <Tooltip
                                            formatter={(value: any, a: any, b: any, idx: number) =>
                                                `${filteredData.restaurants[idx]} ${filteredData.currencySymbol} ${value}`
                                            }
                                        />
                                        {filteredData?.data.map((res: any, i: number) => (
                                            <Line
                                                type="monotone"
                                                dataKey={(initialData) => {
                                                    const index = res.findIndex(
                                                        (obj: any) => obj.date === initialData.date,
                                                    );

                                                    return chartMode === 'tip'
                                                        ? (
                                                              parseFloat(res?.[index]?.revenue_with_tip) -
                                                              parseFloat(res?.[index]?.revenue)
                                                          )?.toFixed(getCurrencyPrecision(filteredData.currencyCode))
                                                        : res?.[index]?.revenue?.toFixed(
                                                              getCurrencyPrecision(filteredData.currencyCode),
                                                          );
                                                }}
                                                stroke={filteredData.colors[i]}
                                                strokeWidth={2}
                                                dot={false}
                                            />
                                        ))}
                                    </LineChart>
                                ) : (
                                    <BarChart
                                        data={filteredData.data?.[0]}
                                        margin={{
                                            top: 40,
                                            right: 30,
                                            left: 0,
                                            bottom: 0,
                                        }}
                                        // add barSize and barGap to make the bars wider and have space between them
                                        barSize={112}
                                        barGap={10}
                                    >
                                        <XAxis dataKey="date" />
                                        <YAxis
                                            domain={[
                                                0,
                                                chartMode === 'revenue'
                                                    ? Math.max(
                                                          ...(filteredData.data || []).flatMap((c: IData[]) =>
                                                              c.map((c1: IData) => Math.round(c1.revenue_with_tip)),
                                                          ),
                                                      )
                                                    : Math.max(
                                                          ...(filteredData.data || []).flatMap((c: IData[]) =>
                                                              c.map((c1: IData) =>
                                                                  parseFloat(
                                                                      (c1.revenue_with_tip - c1.revenue).toFixed(2),
                                                                  ),
                                                              ),
                                                          ),
                                                      ),
                                            ]}
                                        />
                                        <Tooltip
                                            formatter={(value: any, a: any, b: any, idx: number) =>
                                                `${filteredData.restaurants[idx]} ${filteredData.currencySymbol} ${value}`
                                            }
                                        />
                                        {filteredData?.data.map((res: any, i: number) => (
                                            <Bar
                                                dataKey={(initialData) => {
                                                    const index = res.findIndex(
                                                        (obj: any) => obj.date === initialData.date,
                                                    );

                                                    return chartMode === 'tip'
                                                        ? (
                                                              parseFloat(res?.[index]?.revenue_with_tip) -
                                                              parseFloat(res?.[index]?.revenue)
                                                          )?.toFixed(getCurrencyPrecision(filteredData.currencyCode))
                                                        : res?.[index]?.revenue?.toFixed(
                                                              getCurrencyPrecision(filteredData.currencyCode),
                                                          );
                                                }}
                                                fill={filteredData.colors[i]}
                                            />
                                        ))}
                                    </BarChart>
                                )}
                            </ResponsiveContainer>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sx={{ marginTop: '2rem' }}>
                <TableContent
                    large
                    heading={t(`{{period}} performance`, {
                        period: `${period.charAt(0).toUpperCase()}${period.slice(1)}`,
                    })}
                    headCells={
                        <>
                            <TableCell>{t(`Restaurant's name`)}</TableCell>
                            <TableCell>{t(`Total Revenue`)}</TableCell>
                            {showTipCard && <TableCell>{t(`Tips `)}</TableCell>}
                            {filteredData.hasAnyRefund && <TableCell>{t(`Refunds`)}</TableCell>}
                            <TableCell>{t(`Actions`)}</TableCell>
                        </>
                    }
                >
                    {chartData?.restaurants.map((r: string, i: number) => (
                        <RestaurantRow
                            key={i}
                            data={chartData.data?.[i]}
                            name={r}
                            color={chartData.colors[i]}
                            currencySymbol={chartData.currencySymbol}
                            currencyCode={chartData.currencyCode}
                            showTipCard={showTipCard}
                            selected={selectedRestaurants?.includes(r)}
                            setSelectedRestaurants={setSelectedRestaurants}
                            focused={focusedRestaurant === r}
                            setFocusedRestaurant={setFocusedRestaurant}
                            otherFocused={!!focusedRestaurant && focusedRestaurant !== r}
                            restaurants={chartData?.restaurants}
                            hasAnyRefund={filteredData.hasAnyRefund}
                        />
                    ))}
                </TableContent>
            </Grid>
        </>
    );
}
