import React, { useState, useCallback, useImperativeHandle, forwardRef, MutableRefObject, useMemo } from 'react';
import { Dialog, Button, IconButton, Typography } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { useTranslation } from '@/hooks/translations';
import { IQsrOrder } from '@/views/QsrOrders/types';
import DialogTitle from '@mui/material/DialogTitle';
import MenuService from '@/services/menu/menuService';
import QsrPaxCount from '@/components/QsrPaxCount';
import styles from './index.module.scss';

export type QSRGuestOrderType = MutableRefObject<IQSRGuestModalFunctions | null>;

export interface IQSRGuestModalFunctions {
    open: (order: IQsrOrder) => void;
}

interface IProps {
    restaurantId: string;
    onReloadOrder: (refId: string) => Promise<any>;
}

const QsrGuestModal = forwardRef<IQSRGuestModalFunctions, IProps>(({ restaurantId, onReloadOrder }, ref) => {
    const { t } = useTranslation('common');
    const digitalMenuService = MenuService.getInstance();
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = useState(false);
    const [order, setOrder] = useState<IQsrOrder | null>(null);
    const [count, setCount] = useState(0);
    const [submitting, setSubmitting] = useState(false);

    const closeHandler = useCallback(() => {
        setOpen(false);
        setOrder(null);
        setSubmitting(false);
        setCount(0);
    }, []);

    const handleSubmit = useCallback(async () => {
        if (!order) {
            return;
        }

        try {
            setSubmitting(true);
            await digitalMenuService.updateGuestCount(restaurantId, order.refId, count);
            await onReloadOrder(order.refId);
            enqueueSnackbar(t('Guest count updated successfully'), { variant: 'success' });
            closeHandler();
        } catch (error) {
            enqueueSnackbar(t('Failed to update guest count'), { variant: 'error' });
        } finally {
            setSubmitting(false);
        }
    }, [order, count]);

    const openHandler = useCallback((val: IQsrOrder) => {
        setOpen(true);
        setOrder(val);
        setCount(val?.tableData?.paxCount || 0);
    }, []);

    useImperativeHandle(
        ref,
        () => ({
            open: openHandler,
        }),
        [openHandler],
    );

    const tableName = useMemo(() => {
        if (!order) {
            return '';
        }

        const isPager = order?.urlData?.tableId.startsWith('-');
        return isPager
            ? t('Pager {{no}}', { no: order?.urlData?.name || order?.urlData?.tableId.substring(1) })
            : t('Table {{no}}', { no: order?.urlData?.name || order?.urlData?.tableId });
    }, [order]);

    return (
        <Dialog
            open={open}
            onClose={closeHandler}
            PaperProps={{
                classes: {
                    root: styles.guestDialog,
                },
            }}
        >
            <DialogTitle className={styles.title}>
                <IconButton onClick={closeHandler}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <Typography variant="body1" className={styles.header}>
                {t(`Select Guest Count for table:`)}&nbsp;<strong>{tableName}</strong>
            </Typography>
            <div className={styles.guestCount}>
                <QsrPaxCount
                    fullWidth
                    hideTitle
                    value={count}
                    onChange={(val) => {
                        setCount(val);
                    }}
                />
            </div>
            <Button
                type="button"
                fullWidth
                variant="contained"
                disabled={count === 0 || submitting}
                className={styles.btn}
                size="large"
                onClick={handleSubmit}
            >
                {t('Submit')}
            </Button>
        </Dialog>
    );
});

export default QsrGuestModal;
