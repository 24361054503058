import { SVGAttributes } from 'react';
import styles from './index.module.scss';

export const Logo = (props: any) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="26" viewBox="0 0 556.37 175.11" {...props}>
            <g fill={props.color ?? '#7d00d4'}>
                <ellipse cx={483.42} cy={48.09} rx={7.21} ry={6.67} />
                <ellipse cx={464} cy={66.43} rx={7.21} ry={6.67} />
                <ellipse cx={464} cy={48.09} rx={7.21} ry={6.67} />
                <ellipse cx={529.31} cy={48.32} rx={7.21} ry={6.67} />
                <ellipse cx={548.74} cy={66.66} rx={7.21} ry={6.67} />
                <ellipse cx={548.74} cy={48.32} rx={7.21} ry={6.67} />
                <ellipse cx={483.86} cy={132.85} rx={7.21} ry={6.67} />
                <ellipse cx={464.43} cy={114.5} rx={7.21} ry={6.67} />
                <ellipse cx={464.43} cy={132.85} rx={7.21} ry={6.67} />
                <ellipse cx={529.75} cy={132.62} rx={7.21} ry={6.67} />
                <ellipse cx={549.17} cy={114.27} rx={7.21} ry={6.67} />
                <ellipse cx={549.17} cy={132.62} rx={7.21} ry={6.67} />
                <path d="M107.59 42.21v132.9h-35.2v-47.86a30.33 30.33 0 0 1-12.17 10.09 39 39 0 0 1-17.1 3.76 41.37 41.37 0 0 1-22.45-6.1 40.75 40.75 0 0 1-15.23-17.5Q0 106.09 0 91.06t5.54-26.7a43.26 43.26 0 0 1 15.32-18.1 39.71 39.71 0 0 1 22.26-6.43 39.42 39.42 0 0 1 17.7 4A28.4 28.4 0 0 1 73 54.67V42.21ZM68 108.56q4.95-6.23 5-17.9 0-11.86-5-18.19t-14.21-6.33q-8.9 0-13.94 6.53t-5 18.39q0 11.47 4.94 17.6t14 6.13q9.3 0 14.21-6.23ZM294.88 42.21v97.3h-34.21v-13a31 31 0 0 1-11.57 10.88 33.74 33.74 0 0 1-16.1 3.71q-18.79 0-27.89-10.39T196 99.17v-57h35.2V100q0 14.24 12.26 14.24a14.89 14.89 0 0 0 11.67-5.14q4.55-5.15 4.55-13.45V42.21ZM402.27 46a41.07 41.07 0 0 1 15.13 17.5q5.44 11.37 5.44 26.4t-5.44 26.7a42.52 42.52 0 0 1-15.23 18.1 41.64 41.64 0 0 1-39.95 2.47 28.4 28.4 0 0 1-12.16-10.88v13.25h-34.61V.08h35.2v53.6a30.31 30.31 0 0 1 12.16-10.09 39 39 0 0 1 17.11-3.76A40.89 40.89 0 0 1 402.27 46Zm-19.18 62.39q4.91-6.45 4.91-18.52 0-11.46-4.84-17.6t-13.95-6.13q-9.3 0-14.24 6.23t-4.94 17.9q0 11.87 4.94 18.19t14 6.33q9.18 0 14.12-6.43ZM124.27 0h37.36v101.09s-.2 8.93 8.72 9.14 12.66 0 12.66 0v27.87a113.86 113.86 0 0 1-18.25 1.41c-18.08 0-38.91-8.72-40.49-28.87Z" />
            </g>
        </svg>
    );
};

interface IQlubLogoProps extends SVGAttributes<any> {
    compact?: boolean;
    white?: boolean;
}

export const QlubLogo = ({ compact, white, ...rest }: IQlubLogoProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="36" viewBox="0 0 556.37 175.11" {...rest}>
            <g fill={white ? '#fff' : '#7D00D4'}>
                <g className={`${styles.ellipses} ${compact ? styles.compact : ''}`}>
                    <ellipse cx={483.42} cy={48.09} rx={7.21} ry={6.67} />
                    <ellipse cx={464} cy={66.43} rx={7.21} ry={6.67} />
                    <ellipse cx={464} cy={48.09} rx={7.21} ry={6.67} />
                    <ellipse cx={529.31} cy={48.32} rx={7.21} ry={6.67} />
                    <ellipse cx={548.74} cy={66.66} rx={7.21} ry={6.67} />
                    <ellipse cx={548.74} cy={48.32} rx={7.21} ry={6.67} />
                    <ellipse cx={483.86} cy={132.85} rx={7.21} ry={6.67} />
                    <ellipse cx={464.43} cy={114.5} rx={7.21} ry={6.67} />
                    <ellipse cx={464.43} cy={132.85} rx={7.21} ry={6.67} />
                    <ellipse cx={529.75} cy={132.62} rx={7.21} ry={6.67} />
                    <ellipse cx={549.17} cy={114.27} rx={7.21} ry={6.67} />
                    <ellipse cx={549.17} cy={132.62} rx={7.21} ry={6.67} />
                </g>
                <path
                    className={`${styles.text} ${compact ? styles.ghost : ''}`}
                    d="M107.59 42.21v132.9h-35.2v-47.86a30.33 30.33 0 0 1-12.17 10.09 39 39 0 0 1-17.1 3.76 41.37 41.37 0 0 1-22.45-6.1 40.75 40.75 0 0 1-15.23-17.5Q0 106.09 0 91.06t5.54-26.7a43.26 43.26 0 0 1 15.32-18.1 39.71 39.71 0 0 1 22.26-6.43 39.42 39.42 0 0 1 17.7 4A28.4 28.4 0 0 1 73 54.67V42.21ZM68 108.56q4.95-6.23 5-17.9 0-11.86-5-18.19t-14.21-6.33q-8.9 0-13.94 6.53t-5 18.39q0 11.47 4.94 17.6t14 6.13q9.3 0 14.21-6.23ZM294.88 42.21v97.3h-34.21v-13a31 31 0 0 1-11.57 10.88 33.74 33.74 0 0 1-16.1 3.71q-18.79 0-27.89-10.39T196 99.17v-57h35.2V100q0 14.24 12.26 14.24a14.89 14.89 0 0 0 11.67-5.14q4.55-5.15 4.55-13.45V42.21ZM402.27 46a41.07 41.07 0 0 1 15.13 17.5q5.44 11.37 5.44 26.4t-5.44 26.7a42.52 42.52 0 0 1-15.23 18.1 41.64 41.64 0 0 1-39.95 2.47 28.4 28.4 0 0 1-12.16-10.88v13.25h-34.61V.08h35.2v53.6a30.31 30.31 0 0 1 12.16-10.09 39 39 0 0 1 17.11-3.76A40.89 40.89 0 0 1 402.27 46Zm-19.18 62.39q4.91-6.45 4.91-18.52 0-11.46-4.84-17.6t-13.95-6.13q-9.3 0-14.24 6.23t-4.94 17.9q0 11.87 4.94 18.19t14 6.33q9.18 0 14.12-6.43ZM124.27 0h37.36v101.09s-.2 8.93 8.72 9.14 12.66 0 12.66 0v27.87a113.86 113.86 0 0 1-18.25 1.41c-18.08 0-38.91-8.72-40.49-28.87Z"
                />
            </g>
        </svg>
    );
};
