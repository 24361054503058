import axios from 'axios';
import axiosRetry from 'axios-retry';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { SocketConnector } from '@/contexts/socket';
import { setRecaptchaModal } from '@/contexts/event.const';
import EventBus from './event-handler';

export const baseUrl = process.env.NEXT_PUBLIC_BASE_URL || '';
export const customerAppUrl = process.env.NEXT_PUBLIC_CUSTOMER_APP_URL || '';
export const gaToken: string = process.env.NEXT_PUBLIC_GA_TOKEN || '';
export const accessTokenKey = 'token';
export const tokenKey = 'accessToken';
export const refreshTokenKey = 'refreshToken';
export const chosenRestaurantIdKey = 'restaurantId';
export const restaurantPosVendorKey = 'posVendor';
export const qlubLoginModeKey = 'qlub-role';
export const userNameKey = 'qlub-user-name';
export const userRoleKey = 'qlub-user-role';
export const timezoneKey = 'qlub-restaurant-timezone';
export const isFirstLoginKey = 'isFirstLogin';
export const userIdKey = 'userId';
export const notificationSettingsKey = 'notificationSettings';
export const tableViewFilterKey = 'tableViewFilterV2';
export const countryCode = 'countryCode';
export const restaurantDataKey = 'restaurantData';
export const loyaltyProgramDataKey = 'loyaltyProgramData';
export const redirectToPaymentLink = 'redirectToPaymentLink';

export const clearLocalStorage = () => {
    localStorage.removeItem(accessTokenKey);
    localStorage.removeItem(refreshTokenKey);
    localStorage.removeItem(chosenRestaurantIdKey);
    localStorage.removeItem(restaurantPosVendorKey);
    localStorage.removeItem(qlubLoginModeKey);
    localStorage.removeItem(userNameKey);
    localStorage.removeItem(userRoleKey);
    localStorage.removeItem(userIdKey);
    localStorage.removeItem(notificationSettingsKey);
    localStorage.removeItem(tableViewFilterKey);
    localStorage.removeItem(countryCode);
    localStorage.removeItem(tokenKey);
    localStorage.removeItem(restaurantDataKey);
    localStorage.removeItem(loyaltyProgramDataKey);
    localStorage.removeItem(redirectToPaymentLink);
};

let cancelTokenSource = axios.CancelToken.source();

const axiosInstance = axios.create({
    baseURL: baseUrl,
    headers: {
        Accepted: 'application/json',
        'Content-Type': 'application/json',
    },
});

axiosRetry(axiosInstance, {
    retries: 3,
    retryDelay: (retryCount) => 1000 * retryCount,
    retryCondition: (error) => {
        return axiosRetry.isNetworkOrIdempotentRequestError(error) || error.code === 'ECONNABORTED';
    },
});

axiosInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem(accessTokenKey);
    const role = localStorage.getItem(qlubLoginModeKey);
    if (token && role && config.headers) {
        config.headers.authorization = `Bearer ${token}`;
        config.headers.user_type = role;
        config.headers['x-user-type'] = role;
    }
    const userId = localStorage.getItem(userIdKey);
    if (config.headers && userId) {
        config.headers['X-Amzn-Trace-Id'] = `UserID:${userId}`;
    }
    config.cancelToken = cancelTokenSource.token;
    return config;
});

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response && error.response.status === 429) {
            // Cancel all pending requests
            cancelTokenSource.cancel('Too many requests');

            // Create new cancel token source for future requests
            cancelTokenSource = axios.CancelToken.source();

            // Open the recaptcha modal
            EventBus.dispatch(setRecaptchaModal);

            // You might want to add a delay before allowing new requests
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve(axios(error.config));
                }, 5000);
            });
        }
        return Promise.reject(error);
    },
);

const refreshAuthLogic = (failedRequest: any) => {
    const refreshToken = localStorage.getItem(refreshTokenKey);
    const role = localStorage.getItem(qlubLoginModeKey);
    return axiosInstance
        .post(`/auth/refresh`, { refreshToken, type: role })
        .then((tokenRefreshResponse) => {
            localStorage.setItem(accessTokenKey, tokenRefreshResponse.data?.data.token || '');
            localStorage.setItem(tokenKey, tokenRefreshResponse.data?.data.access_token || '');
            const restaurantId = localStorage.getItem(chosenRestaurantIdKey);
            // Connect the socket with new access token
            if (tokenRefreshResponse.data?.data.token && restaurantId) {
                SocketConnector.getInstance().destroy();
                SocketConnector.getInstance()
                    .modify(
                        {
                            accessToken: tokenRefreshResponse.data?.data.token,
                            restaurantId,
                        },
                        true,
                    )
                    .catch(() => {
                        console.log('Error while connecting with socket with new token');
                    });
            }
            failedRequest.response.config.headers.authorization = `Bearer ${tokenRefreshResponse.data}`;
            return Promise.resolve();
        })
        .catch((err) => {
            clearLocalStorage();
            window.location.reload();
            return Promise.reject(err);
        });
};

// Instantiate the interceptor
createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic, {
    statusCodes: [401],
});

export default axiosInstance;
