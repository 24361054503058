import React, { useState, useRef } from 'react';
import { Autocomplete, TextField, Chip } from '@mui/material';
import { emailRegex } from '@/constants/regexes';
import { useTranslation } from '@/hooks/translations';
import { useUserContext } from '@/contexts/user';
import { BrandReportPreferences, RestaurantReportPreferences } from '@/services/utils/schedulerReport_helper';

interface EmailAutocompleteProps {
    reportPreferences: RestaurantReportPreferences | BrandReportPreferences;
    setReportPreferences: (preferences: RestaurantReportPreferences | BrandReportPreferences) => void;
}

const EmailAutocomplete: React.FC<EmailAutocompleteProps> = ({ reportPreferences, setReportPreferences }) => {
    const { t } = useTranslation('common');
    const { user } = useUserContext();
    const [inputValue, setInputValue] = useState('');
    const [errorText, setErrorText] = useState('');
    const textFieldRef = useRef<HTMLInputElement>(null);

    const handleEmailChange = (newVals: string[]) => {
        const validEmails = Array.from(new Set(newVals.filter((val) => emailRegex.test(val))));
        const invalidEmails = newVals.filter((val) => !emailRegex.test(val));

        if (invalidEmails.length > 0) {
            setErrorText(t('Invalid email ID'));
        } else {
            setErrorText('');
        }

        const newValsExcludingUser = validEmails.filter((val) => val !== user?.email);
        setReportPreferences({ ...reportPreferences, cc: newValsExcludingUser });
    };

    const handleBlur = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.target.value) {
            handleEmailChange([...reportPreferences.cc, event.target.value]);
        }
        setInputValue('');
    };

    return (
        <Autocomplete
            sx={{
                margin: '1rem 0',
            }}
            multiple
            fullWidth
            id="tags-filled"
            options={[]}
            onChange={(event, newVals) => handleEmailChange(newVals)}
            freeSolo
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => {
                return (
                    <TextField
                        {...params}
                        inputRef={textFieldRef}
                        variant="outlined"
                        placeholder={t('Email address of recipient')}
                        helperText={errorText || t('Add multiple emails by pressing enter for more than one recipient')}
                        size="small"
                        error={!!errorText}
                        onBlur={handleBlur}
                    />
                );
            }}
            value={[user?.email, ...reportPreferences.cc]}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip label={option} {...getTagProps({ index })} disabled={option === user?.email} />
                ))
            }
        />
    );
};

export default EmailAutocomplete;
