import React, {
    useState,
    useCallback,
    useImperativeHandle,
    forwardRef,
    useRef,
    useEffect,
    MutableRefObject,
} from 'react';
import { Drawer } from '@mui/material';
import IframeBridge from '@/services/iframeBridge';
import MenuService from '@/services/menu/menuService';
import { IOrderTableData, IQsrOrder } from '@/views/QsrOrders/types';
import { customerAppUrl } from '@/config/axios';
import { IURLTopology } from '@/services/iframeBridge/type';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'next-export-i18n';
import { timeUnix } from '@/services/utils/k_time';
import { IQR } from '@/views/QRCode/types';
import { IRestaurant, IRestaurantTables } from '@/views/OrdersTableView/types';
import { useRestaurantContext } from '@/contexts/restaurant';
import Splash from '@/components/Splash';

import styles from './index.module.scss';

export enum OrderInteractionModeEnum {
    Edit = 'edit',
    Create = 'create',
}

export interface ICreateOrderTable {
    qr: IQR | IRestaurantTables;
    tableData?: IOrderTableData;
}

const getEditIframeUrl = (order: IQsrOrder) => {
    return `${customerAppUrl}/qr/${order.urlData.cc}/${order.urlData.slug}/${order.urlData.tableId}/${order.urlData.f1}/${order.urlData.f2}/${order.urlData.hash}/embed/qsr/order_details?theme=black&lang=en&qsr=true&embed=vendor`;
};

const getCreateIframeUrl = (restaurant: IRestaurant | null, qr: IQR | IRestaurantTables) => {
    return `${customerAppUrl}/qr/${restaurant?.country_code}/${restaurant?.restaurant_unique}/${qr.params.id}/${
        qr.params.f1 || '_'
    }/${qr.params.f2 || '_'}/${qr.params.hash}/embed/qsr?theme=normal&lang=en&qsr=true&embed=vendor`;
};

const getUrlByOrder = (order: IQsrOrder): IURLTopology => {
    return {
        cc: order.urlData.cc,
        slug: order.urlData.slug,
        id: order.urlData.tableId,
        f1: order.urlData.f1,
        f2: order.urlData.f2,
        hash: order.urlData.hash || '_',
    };
};

const getUrlByQr = (restaurant: IRestaurant, qr: IQR | IRestaurantTables): IURLTopology => {
    return {
        cc: restaurant.country_code,
        slug: restaurant.restaurant_unique,
        id: qr.params.id,
        f1: qr.params.f1 || '_',
        f2: qr.params.f2 || '_',
        hash: qr.params.hash || '_',
    };
};

export type QSREditOrderType = MutableRefObject<IQSREditOrderFunctions | null>;

export interface IQSREditOrderFunctions {
    edit: (order: IQsrOrder) => void;
    create: (table: ICreateOrderTable) => void;
}

interface IProps {
    onReloadOrder: (refId: string) => Promise<any>;
}

const QSREmbedOrder = forwardRef<IQSREditOrderFunctions, IProps>(({ onReloadOrder }, ref) => {
    const { t } = useTranslation('common');
    const { enqueueSnackbar } = useSnackbar();
    const { restaurant } = useRestaurantContext();
    const [open, setOpen] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState('');
    const lastTokenUnix = useRef(0);
    const [url, setUrl] = useState('');
    const [orderMode, setOrderMode] = useState<OrderInteractionModeEnum | null>(null);
    const [order, setOrder] = useState<IQsrOrder | null>(null);
    const [table, setTable] = useState<ICreateOrderTable | null>(null);
    const iframeService = useRef<IframeBridge | null>(null);

    const closeHandler = useCallback(() => {
        setOpen(false);
        setOrderMode(null);
        setOrder(null);
        setTable(null);
    }, []);

    const isTokenValid = useCallback(() => {
        const unix = timeUnix();
        return unix - lastTokenUnix.current < 60 * 60;
    }, []);

    const loadOrder = useCallback(
        ({ refId, token: _token, order: _order }: { refId: string; token: string; order: IQsrOrder }) => {
            console.log('send loadOrder');
            setTimeout(() => {
                iframeService.current
                    ?.loadOrder({ refId, token: _token, url: getUrlByOrder(_order) })
                    .then(() => {
                        setLoading(false);
                    })
                    .catch((err) => {
                        enqueueSnackbar(err.err || err.message || err, { variant: 'error' });
                    });
            }, 100);
        },
        [],
    );

    const editHandler = useCallback(
        (val: IQsrOrder) => {
            if (!restaurant) {
                return;
            }

            setOrderMode(OrderInteractionModeEnum.Edit);

            setOpen(true);
            setLoading(true);
            if (!url) {
                setUrl(getEditIframeUrl(val));
            }

            if (isTokenValid()) {
                setOrder(val);
                if (loaded && token && url) {
                    loadOrder({ refId: val.refId, token, order: val });
                }
            } else {
                setToken('');
                setTimeout(() => {
                    setOrder(val);
                }, 100);
            }
        },
        [token, loaded, url, loadOrder, restaurant],
    );

    const createOrder = useCallback(
        ({ token: _token, table: _table }: { token: string; table: ICreateOrderTable }) => {
            if (!restaurant) {
                return;
            }

            console.log('send createOrder');
            setTimeout(() => {
                iframeService.current
                    ?.createOrder({
                        token: _token,
                        url: getUrlByQr(restaurant, _table.qr),
                        tableData: _table?.tableData,
                    })
                    .then(() => {
                        setLoading(false);
                    })
                    .catch((err) => {
                        enqueueSnackbar(err.err || err.message || err, { variant: 'error' });
                    });
            }, 100);
        },
        [restaurant],
    );

    const createHandler = useCallback(
        (val: ICreateOrderTable) => {
            if (!restaurant) {
                return;
            }

            setOrderMode(OrderInteractionModeEnum.Create);

            setOpen(true);
            setLoading(true);
            if (!url) {
                setUrl(getCreateIframeUrl(restaurant, val.qr));
            }

            if (isTokenValid()) {
                setTable(val);
                if (loaded && token && url) {
                    createOrder({ token, table: val });
                }
            } else {
                setToken('');
                setTimeout(() => {
                    setTable(val);
                }, 100);
            }
        },
        [token, loaded, url, createOrder, restaurant],
    );

    useEffect(() => {
        if (!token || !loaded) {
            return;
        }

        if (order && orderMode === OrderInteractionModeEnum.Edit) {
            loadOrder({ refId: order.refId, token, order });
        } else if (table && orderMode === OrderInteractionModeEnum.Create) {
            createOrder({ token, table });
        }
    }, [loaded]);

    useImperativeHandle(
        ref,
        () => {
            return {
                edit: editHandler,
                create: createHandler,
            };
        },
        [editHandler, createHandler],
    );

    const generateToken = useCallback(
        (correct?: boolean) => {
            if (!restaurant?.id) {
                return Promise.reject(new Error('restaurant id is not found'));
            }

            return MenuService.getInstance()
                .getVendorTokenAccess(restaurant.id)
                .then((res) => {
                    setToken(res.token);
                    return res.token;
                })
                .catch((err) => {
                    enqueueSnackbar(t('cannot generate token!'), { variant: 'error' });
                    return Promise.reject(err);
                });
        },
        [restaurant],
    );

    useEffect(() => {
        if (!restaurant?.id || token) {
            return;
        }

        if (timeUnix() - lastTokenUnix.current < 5) {
            return;
        }

        lastTokenUnix.current = timeUnix();

        generateToken().then((res) => {
            if (order && orderMode === OrderInteractionModeEnum.Edit) {
                loadOrder({ refId: order.refId, token: res, order });
            } else if (table && orderMode === OrderInteractionModeEnum.Create) {
                createOrder({ token: res, table });
            }
        });
    }, [restaurant, token, order, table, generateToken]);

    const updateToken = useCallback(() => {
        return generateToken().then((res) => {
            return (
                iframeService.current?.updateToken({ token: res }).then(() => {
                    return Promise.resolve({
                        data: {
                            success: true,
                        },
                    });
                }) ||
                Promise.resolve({
                    data: {
                        success: false,
                    },
                })
            );
        });
    }, [generateToken]);

    const iframeRefHandler = useCallback(
        (iframe: HTMLIFrameElement) => {
            if (!iframe) {
                return () => {
                    //
                };
            }

            iframeService.current = IframeBridge.getInstance(iframe);
            iframeService.current.onLoad(() => {
                console.log('vendor | customer app loaded');
                setLoaded(true);
            });

            const cancellers = [
                iframeService.current?.api('/order/submit', (res) => {
                    onReloadOrder(res.data?.order?.refId || '').finally(() => {
                        closeHandler();
                    });
                    return Promise.resolve({
                        data: {
                            success: true,
                        },
                    });
                }),
                iframeService.current?.api('/order/error', (res) => {
                    if (res.data?.error?.statusCode === 403) {
                        return updateToken();
                    }
                    return Promise.resolve({
                        data: {
                            success: true,
                        },
                    });
                }),
            ];

            return () => {
                cancellers.forEach((fn) => {
                    fn?.();
                });
            };
        },
        [updateToken],
    );

    const loadHandler = useCallback(() => {
        console.log('loaded');
    }, []);

    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={closeHandler}
            keepMounted
            PaperProps={{
                sx: {
                    overflowY: 'hidden',
                },
            }}
        >
            <div className={styles.content}>
                {url && <iframe ref={iframeRefHandler} onLoad={loadHandler} title="Edit Order" src={url} />}
                {loading && (
                    <div className={styles.loading}>
                        <Splash />
                    </div>
                )}
            </div>
        </Drawer>
    );
});

export default QSREmbedOrder;
