import { parseJSON } from '@/components/TicketModal/utils';
import { TimeRangeTypes } from '@/components/TimeRange/Form/types';
import { formatISO, startOfDay, startOfMonth } from 'date-fns';

const filterKey = 'qsr_order_filter';

interface IFilter {
    paymentStatus?: string[];
    posStatus?: string[];
    selectedStatus?: string[];
    selectedTable?: { ids: string[]; qrs: string[]; qrGroups: string[] };
    timeRange?: { type: string; from: string | null; to: string | null };
    view?: string;
}

const filters: {
    val: IFilter | null;
} = {
    val: null,
};

export const getLsValue = (): IFilter => {
    if (filters.val) {
        return filters.val;
    }

    const v = parseJSON(localStorage.getItem(filterKey));
    if (v) {
        filters.val = v || {};
    }
    return filters.val || {};
};

export const getLsItemValue = (key: keyof IFilter): any => {
    const v = getLsValue();
    if (key === 'timeRange') {
        const { type } = v[key] || {};
        const date = new Date();
        switch (type) {
            case TimeRangeTypes.TODAY:
                return {
                    ...v[key],
                    from: formatISO(startOfDay(date)),
                    to: null,
                };
            case TimeRangeTypes.MONTH:
                return {
                    ...v[key],
                    from: formatISO(startOfMonth(date)),
                    to: null,
                };
            case TimeRangeTypes.LAST_30_DAYS:
                return {
                    ...v[key],
                    from: formatISO(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 30)),
                    to: null,
                };
            default:
                return {
                    ...v[key],
                };
        }
    }
    return v[key];
};

export const setLsValue = (val: IFilter) => {
    filters.val = val;
    localStorage.setItem(filterKey, JSON.stringify(val));
};

export const setLsItemValue = (key: keyof IFilter, val: any) => {
    const v = getLsValue();
    setLsValue({
        ...v,
        [key]: val,
    });
};
