export interface IPrinterDetail {
    id: string;
    uuid: string;
    type?: PrintTypeEnum;
    status: PrintStatusEnum;
    printData: PrintData;
    error?: string;
    createdBy: string;
    createdAt: string;
    updatedAt: string;
    printerId: string;
    printer: Printer;
    printBlobUrl?: string;
}

interface Printer {
    id: string;
    restaurantId: string;
    name: string;
    type: string;
}

interface PrintData {
    [key: string]: string;
}

export interface IParams {
    page: number;
    limit: number;
    currentStatus: string;
    printId: string;
    printerType: PrinterTypeEnum;
    printType: PrintTypeEnum;
    printerId: string;
}

export enum PrintStatusEnum {
    PENDING = 'PENDING',
    TRANSFORMER = 'TRANSFORMER',
    PRINTING = 'PRINTING',
    IMAGE = 'IMAGE',
    CANCELED = 'CANCELED',
    FAILED = 'FAILED',
    SUCCESS = 'SUCCESS',
    RETRIED = 'RETRIED',
}

export enum PrinterTypeEnum {
    ALL = 'ALL',
    EPSON = 'EPSON',
    CHEF = 'CHEF',
    SOFTPOS = 'SOFTPOS',
}

export enum PrintTypeEnum {
    ORDER = 'ORDER',
    PAYMENT = 'PAYMENT',
}
