import { PaymentRecordType } from '@/views/Payments';
import { Box, Button, Dialog, DialogContent, DialogTitle, SxProps } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from '@/hooks/translations';
import { INotificationSettings } from '@/views/Settings/content/notification-settings-content';
import { IQsrOrder, NOTIFICATION_TYPES } from '@/views/QsrOrders/types';
import { IPaymentTransaction } from '@/views/Orders/types';

const DialogProps: SxProps = { width: '100vw', borderRadius: '20px', maxWidth: '1000px' };
const DialogTitleProps: SxProps = {
    border: '0px',
    borderBottom: '1px',
    borderColor: '#EBECF2',
    borderStyle: 'solid',
    width: '100%',
    paddingLeft: '32px',
};
const BoxProps: SxProps = { display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' };
const ButtonProps: SxProps = { color: '#ffffff', width: '100%', marginTop: '10px' };

export const useNotificationTranslation = () => {
    const { t } = useTranslation('common');

    const getMessage = (type: NOTIFICATION_TYPES, payload: IPaymentTransaction | IQsrOrder) => {
        switch (type) {
            case NOTIFICATION_TYPES.PAYMENT_RECEIVED:
                {
                    const data = payload as IPaymentTransaction;
                    const payments = data?.paymentRecord?.filter(
                        (o) => !o.type || o.type === PaymentRecordType.User || o.type === PaymentRecordType.NonQlubUser,
                    );

                    const name = data?.qr?.params?.name || data?.table_data?.name || data?.table_id;
                    return Number(payments?.[0]?.tip_amount)
                        ? t(`Table - {{table_id}} {{eventName}} for {{amount}} and {{tipAmount}} tip`, {
                              table_id: name,
                              eventName: type,
                              amount: payments?.[0]?.paid_amount,
                              tipAmount: Number(payments?.[0]?.tip_amount) - (payments?.[0]?.tip_commission || 0),
                          })
                        : t(`Table - {{table_id}} {{eventName}} for {{amount}}`, {
                              table_id: name,
                              eventName: type,
                              amount: payments?.[0]?.paid_amount,
                          });
                }
                break;
            case NOTIFICATION_TYPES.ORDER_RECEIVED:
                return t(`New Order`);
            default:
                return '';
        }
    };
    return { getMessage };
};

// popupNotification props typee
export interface IPopupNotificationProps {
    msg: IPaymentTransaction;
    eventName: NOTIFICATION_TYPES;
    notifySettings: INotificationSettings;
    onClose: () => void;
}

export const PopupNotification = ({ msg, eventName, notifySettings, onClose }: IPopupNotificationProps) => {
    const { t } = useTranslation('common');
    const { getMessage } = useNotificationTranslation();
    const [open, setOpen] = useState(true);

    const handleClose = () => {
        setOpen(false);
        onClose();
    };

    useEffect(() => {
        const fn = setTimeout(() => {
            handleClose();
        }, notifySettings.timeout * 1000);
        return () => {
            clearTimeout(fn);
        };
    }, []);

    return (
        <Dialog open={open} onClose={handleClose} sx={DialogProps}>
            <DialogTitle sx={DialogTitleProps}>{t('You have a new Payment!')}</DialogTitle>
            <DialogContent>
                <Box sx={BoxProps}>
                    {msg && getMessage(eventName, msg)}
                    {t(' has been received.')}
                </Box>
                <Button variant="contained" sx={ButtonProps} onClick={handleClose}>
                    {t('Ok')}
                </Button>
            </DialogContent>
        </Dialog>
    );
};
