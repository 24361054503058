import { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { RemoveRounded, AddRounded } from '@mui/icons-material';
import { useTranslation } from '@/hooks/translations';
import classNames from 'classnames';

import styles from './index.module.scss';

interface IProps {
    hideTitle?: boolean;
    fullWidth?: boolean;
    value?: number;
    onChange?: (val: number) => void;
}

const QsrPaxCount = ({ hideTitle, fullWidth, value, onChange }: IProps) => {
    const { t } = useTranslation('common');

    const [count, setCount] = useState(0);

    useEffect(() => {
        if (!value) {
            return;
        }

        setCount(value);
    }, [!value ? 'ok' : 'init']);

    useEffect(() => {
        onChange?.(count);
    }, [count]);

    return (
        <div className={classNames(styles.container, { [styles.fullWidth]: fullWidth })}>
            {!hideTitle && <div className={styles.title}>{t('Guest count')}</div>}
            <div className={styles.action}>
                <div className={styles.button}>
                    <Button
                        aria-label="reduce"
                        disabled={count === 0}
                        onClick={() => {
                            setCount(Math.max(0, count - 1));
                        }}
                    >
                        <RemoveRounded fontSize="large" />
                    </Button>
                    <div className={styles.display}>
                        <input
                            type="number"
                            inputMode="numeric"
                            step={1}
                            value={count.toFixed(0)}
                            onChange={(e) => {
                                setCount(Math.max(0, parseInt(e.target.value, 10)));
                            }}
                        />
                    </div>
                    <Button
                        aria-label="increase"
                        onClick={() => {
                            setCount(count + 1);
                        }}
                    >
                        <AddRounded fontSize="large" />
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default QsrPaxCount;
