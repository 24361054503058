import React from 'react';
import { QlubLogo } from '@/components/icon/logo';

import styles from './index.module.scss';

interface IProps {
    hideIcon?: boolean;
}

export default function Splash({ hideIcon }: IProps) {
    return (
        <div className={styles.splash}>
            <div className={styles.inner}>
                <div className={styles.container}>
                    {hideIcon !== true && <QlubLogo className={styles.logo} white />}
                </div>
                <div className={styles.loading}>
                    <div className={styles.kkEllipsis}>
                        <div />
                        <div />
                        <div />
                        <div />
                    </div>
                </div>
            </div>
        </div>
    );
}
