import { useCallback, useEffect, useState } from 'react';
import PrintIcon from '@mui/icons-material/Print';
import { CustomButton } from '@/components/common';
import {
    EpsonPrinterService,
    PrintConnectionStatusEnum,
    PrintEventEnum,
} from '@/components/PosPrinteCenter/Printers/Epson';
import { useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from '@/hooks/translations';
import { PosPrintModeEnum } from '@/components/PosPrinteCenter/QsrOrderSettingsV2';
import CustomModal from '@/components/CustomModal';
import { IPrinter, IVendorQsrOrderConfig } from '@/views/OrdersTableView/types';
import { useRestaurantContext } from '@/contexts/restaurant';
import PrintIndicator from '@/components/PrintIndicator';
import PrinterService from '@/services/printer/printerService';
import { PrinterTypeEnum } from '@/interfaces/printer';
import { useSnackbar } from 'notistack';
import dynamic from 'next/dynamic';
import { transformPrinterConfig } from '@/components/PosPrinteCenter/QsrOrderSettings';
import { isEqual } from 'lodash';
import RestaurantService from '@/services/restaurant';

interface IProps {
    loading?: boolean;
    mode?: PosPrintModeEnum;
    label?: any;
}

const QsrOrderSettings = dynamic(() => import('../PosPrinteCenter/QsrOrderSettings'), { ssr: false });
const QsrOrderSettingsV2 = dynamic(() => import('../PosPrinteCenter/QsrOrderSettingsV2'), { ssr: false });

const PrintSettingsButton = ({ loading, mode, label }: IProps) => {
    const { t } = useTranslation('common');
    const printerService = PrinterService.getInstance();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(650));
    const { restaurant, setRestaurant } = useRestaurantContext();
    const [printerConnectionStatus, setPrinterConnectionStatus] = useState<PrintConnectionStatusEnum>(
        PrintConnectionStatusEnum.Disconnected,
    );
    const [enablePrinter, setEnablePrinter] = useState(false);
    const [open, setOpen] = useState(false);
    const [config, setConfig] = useState<IVendorQsrOrderConfig>({});
    const [printerConfig, setPrinterConfig] = useState([]);
    const [loadingSubmit, setLoadingSubmit] = useState(false);

    const printerVersion2 = restaurant?.order_config?.printerV2;

    const getPrinterConfig = async () => {
        if (!restaurant) return;
        try {
            const res = await printerService.getPrinterList(restaurant?.id, PrinterTypeEnum.EPSON);
            setPrinterConfig(res.rows);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        getPrinterConfig();
    }, [restaurant?.id]);

    useEffect(() => {
        const posPrintService = EpsonPrinterService.getInstance();
        setPrinterConnectionStatus(posPrintService.getConnectionStatus());
        const statusChangeFn = posPrintService.on(
            PrintEventEnum.StatusChange,
            ({ status }: { status: PrintConnectionStatusEnum }) => {
                setPrinterConnectionStatus(status);
            },
        );
        return () => {
            statusChangeFn();
        };
    }, []);

    const openHandler = useCallback(() => {
        setOpen(true);
    }, []);

    const closeHandler = useCallback(() => {
        setOpen(false);
    }, []);

    const actionHandler = useCallback(() => {
        closeHandler();
        const posPrintService = EpsonPrinterService.getInstance();
        if (enablePrinter) {
            posPrintService.init(config);
        }
        if (!isEqual(config, {}) && restaurant) {
            RestaurantService.getInstance()
                .editRestaurantOrderConfig(restaurant.id || '', {
                    orderConfig: transformPrinterConfig(config),
                })
                .then((res) => {
                    setRestaurant(res);
                });
        }
    }, [config, enablePrinter, restaurant]);

    const actionHandlerV2 = useCallback(
        (conf: IPrinter) => {
            if (!restaurant) return;
            const data = {
                ...conf,
                config: {
                    printerIP: conf?.config?.printerIP || '',
                    printerPort: conf?.config?.printerPort || '',
                    printerEncrypt: conf?.config?.printerEncrypt ?? false,
                    printerDeviceId: conf?.config?.printerDeviceId || '',
                },
            };

            const posPrintService = EpsonPrinterService.getInstance();
            if (enablePrinter) {
                posPrintService.init(data.config);
            }
            setLoadingSubmit(true);
            printerService
                .updatePrinterConfig(restaurant.id, data.id, data.config)
                .then(() => {
                    getPrinterConfig();
                    closeHandler();
                    enqueueSnackbar(t('Printer updated successfully'), { variant: 'success' });
                })
                .catch((err) => {
                    enqueueSnackbar(t('Failed to update printer'), { variant: 'error' });
                    console.log(err);
                })
                .finally(() => {
                    setLoadingSubmit(false);
                });
        },
        [enablePrinter, restaurant],
    );

    return (
        <>
            <CustomButton
                onClick={openHandler}
                startIcon={isMobile ? null : <PrintIcon />}
                endIcon={
                    <PrintIndicator
                        status={printerConnectionStatus}
                        onReconnect={() => {
                            EpsonPrinterService.getInstance().reconnect();
                        }}
                    />
                }
                fullWidth
                sx={{
                    color: 'primary.main',
                    borderRadius: '32px',
                    padding: '10px 24px 10px 16px;',
                }}
            >
                {label || t('Print')}
            </CustomButton>
            {printerVersion2 ? (
                <CustomModal
                    open={open}
                    title={t('Settings')}
                    actionText={t('Apply')}
                    maxWidth="sm"
                    disableButton={loading}
                    onClose={closeHandler}
                    noDialogActions
                >
                    <QsrOrderSettingsV2
                        data={printerConfig}
                        loading={loadingSubmit}
                        mode={mode}
                        onChange={(active: boolean) => {
                            setEnablePrinter(active);
                        }}
                        onSubmit={actionHandlerV2}
                    />
                </CustomModal>
            ) : (
                <CustomModal
                    open={open}
                    title={t('Settings')}
                    actionText={t('Apply')}
                    maxWidth="sm"
                    disableButton={loading}
                    onClose={closeHandler}
                    actionMethod={actionHandler}
                >
                    <QsrOrderSettings
                        mode={mode}
                        onChange={(cfg, active) => {
                            setEnablePrinter(active);
                            setConfig(cfg);
                        }}
                    />
                </CustomModal>
            )}
        </>
    );
};

export default PrintSettingsButton;
