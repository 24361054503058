import { useState } from 'react';
import { useTranslation } from '@/hooks/translations';
import AddRounded from '@mui/icons-material/AddRounded';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';

import styles from './index.module.scss';

interface IProps {
    onClick: (e: any) => void;
}

const QsrCreateOrderButton = ({ onClick }: IProps) => {
    const { t } = useTranslation('common');
    const [open, setOpen] = useState(false);

    return (
        <div className={styles.button}>
            <Tooltip
                title={t('Add a new Order')}
                enterDelay={0}
                placement="top"
                open={open}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
            >
                <Fab
                    color="primary"
                    onTouchStart={() => {
                        setOpen(true);
                    }}
                    onClick={onClick}
                >
                    <AddRounded />
                </Fab>
            </Tooltip>
        </div>
    );
};

export default QsrCreateOrderButton;
