import { Grid, Typography, Button } from '@mui/material';
import { AddRounded } from '@mui/icons-material';
import { useTranslation } from 'next-export-i18n';
import { OrderStatusEnum, IQsrOrderNotification } from '@/views/QsrOrders/types';
import { IRestaurant } from '@/views/OrdersTableView/types';
import { IOrderItemStatus } from '@/components/QsrOrderAction';
import QsrTableViewCardElement from './qsrTableViewCardElement';

interface IQsrTableViewProps {
    data: IQsrOrderNotification[] | null;
    currencyCode: string;
    currencySymbol: string;
    restaurant: IRestaurant | null;
    loading?: boolean;
    onBatchChange: (payload: IOrderItemStatus) => void;
    onDone: (status: OrderStatusEnum, ticketId: string, refId: string) => void;
    onPrint: (id: string) => void;
    onLoadMore?: () => void;
    onEdit: (id: string) => void;
    onReloadOrder: (refId: string) => Promise<any>;
    onGuestChange: (id: string) => void;
}

export default function QsrGridView({
    data,
    currencyCode,
    currencySymbol,
    restaurant,
    loading,
    onBatchChange,
    onDone,
    onPrint,
    onLoadMore,
    onEdit,
    onReloadOrder,
    onGuestChange,
}: IQsrTableViewProps) {
    const { t } = useTranslation('common');

    return data ? (
        <Grid container spacing={1}>
            {data.map((item) => {
                return (
                    <Grid item xs={12} sm={6} md={4} key={item.id}>
                        <QsrTableViewCardElement
                            onGuestChange={onGuestChange}
                            data={item}
                            currencySymbol={currencySymbol}
                            currencyCode={currencyCode}
                            restaurant={restaurant}
                            onBatchChange={onBatchChange}
                            onDone={onDone}
                            onPrint={onPrint}
                            onEdit={onEdit}
                            onReloadOrder={onReloadOrder}
                        />
                    </Grid>
                );
            })}
            {onLoadMore && (
                <Grid item xs={12} sm={6} md={4}>
                    <Button
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            minHeight: '440px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '8px',
                        }}
                        variant="outlined"
                        fullWidth
                        onClick={onLoadMore}
                        disabled={loading}
                    >
                        <AddRounded fontSize="large" color={loading ? 'disabled' : 'primary'} />
                        {t('Load more...')}
                    </Button>
                </Grid>
            )}
        </Grid>
    ) : (
        <Typography variant="h6">{t('There is no data')}</Typography>
    );
}
