import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import randomColor from 'randomcolor';

import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';

import DashboardService from '@/services/dashboard';
import { onPushEvent } from '@/services/lib/gtm';
import { useTranslation } from '@/hooks/translations';
import { useUserContext } from '@/contexts/user';
import { useRestaurantContext } from '@/contexts/restaurant';
import { covertUtcToRestaurantDateTime, ReportType } from '@/common/utility';
import { FilterProps } from '@/views/Dashboard';
import { Section } from '@/components/common';
import RestaurantChart from './RestaurantChart';
import BrandChart from './BrandChart';
import SkeletonLoadingContent from '../SkeletonLoadingContent';

type Props = {
    type: ReportType;
};

const skeletonRevenueChart = () => (
    <Grid container spacing={2}>
        <Grid item xs={12}>
            <Skeleton variant="rectangular" sx={{ height: '300px', borderRadius: '8px' }} />
        </Grid>
    </Grid>
);

export default function RevenueChart({ timeRange, type }: FilterProps & Props) {
    const dashboardService = DashboardService.getInstance();
    const { t } = useTranslation('common');
    const router = useRouter();
    const { lang } = router.query as { lang?: string };
    const [chartData, setChartData] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>('');
    const { user } = useUserContext();
    const { restaurant, selectedProductType } = useRestaurantContext();
    const [chartMode, setChartMode] = useState<'revenue' | 'tip'>('revenue');

    const showTip = !restaurant?.config?.disableTipForVendorUsers || false;
    const vendor_enableTipCommission = restaurant?.config?.vendor_enableTipCommission || false;
    const disableTipCardAndChart = restaurant?.restaurant_country?.config?.disableTipCardAndChart || false;
    const hideTip = vendor_enableTipCommission && disableTipCardAndChart;
    const isTransactionPoweredApi = restaurant?.config?.isTransactionPoweredApi || false;

    const showTipCard = showTip && !hideTip;
    const { from, to } = timeRange;
    const period = timeRange.type;

    const handleTabChange = (event: React.SyntheticEvent, newValue: 'revenue' | 'tip') => {
        setChartMode(newValue);
        onPushEvent(`user_select_tip_show_on_revenue_chart_to_${newValue || false}`);
    };

    const baseName = type === ReportType.RESTAURANT ? 'restaurant' : 'brand';
    const id = type === ReportType.RESTAURANT ? user.restaurantId : restaurant?.brand_id;

    useEffect(() => {
        if (
            (type === ReportType.BRAND && restaurant?.brand_id) ||
            (type === ReportType.RESTAURANT && user.restaurantId)
        ) {
            setLoading(true);
            dashboardService
                .getRevenueChartReport({
                    name: baseName,
                    period,
                    from,
                    to,
                    id,
                    funnel: selectedProductType,
                    isTransactionPoweredApi,
                })
                .then((res) => {
                    const resChartData = res.data;
                    const { revenueChart, currencyCode, currencySymbol } = resChartData;

                    if (type === ReportType.RESTAURANT) {
                        setChartData(resChartData);
                    } else {
                        // separating chart data for multiple restaurants in brand
                        const chartDataMapping: any = {};
                        const revenueMaxValue = Math.max(...(revenueChart || []).map((c: any) => Math.round(c.paid)));
                        const tipMaxValue = Math.max(...(revenueChart || []).map((c: any) => c.tip));

                        (revenueChart || []).forEach((c: any) => {
                            const details = {
                                date: covertUtcToRestaurantDateTime(c.date, 'DD MMM'),
                                revenue: parseFloat(c.paid),
                                revenue_with_tip: parseFloat(c.paidAmountWithTip),
                                refunds: parseFloat(c.refunds),
                            };
                            if (chartDataMapping[c.restaurantUnique]) {
                                chartDataMapping[c.restaurantUnique].push(details);
                            } else {
                                chartDataMapping[c.restaurantUnique] = [details];
                            }
                        });

                        setChartData({
                            data: Object.values(chartDataMapping),
                            colors: randomColor({ count: Object.keys(chartDataMapping).length }),
                            restaurants: Object.keys(chartDataMapping) || [],
                            currencyCode: currencyCode || '',
                            currencySymbol: currencySymbol || '',
                            revenueMaxValue,
                            tipMaxValue,
                        });
                    }
                })
                .catch(() => {
                    setError(t('Something went wrong'));
                })
                .finally(() => setLoading(false));
        }
    }, [user, restaurant?.brand_id, selectedProductType, timeRange]);

    return (
        <Grid item xs={12}>
            <Section sx={{ borderColor: '#E5E5E5' }}>
                <SkeletonLoadingContent loading={loading} error={error} skeleton={skeletonRevenueChart()}>
                    {type === ReportType.RESTAURANT && (
                        <RestaurantChart
                            chartData={chartData}
                            chartMode={chartMode}
                            handleTabChange={handleTabChange}
                            showTipCard={showTipCard}
                            lang={lang}
                        />
                    )}
                    {type === ReportType.BRAND && (
                        <BrandChart
                            chartData={chartData}
                            chartMode={chartMode}
                            handleTabChange={handleTabChange}
                            showTipCard={showTipCard}
                            period={period}
                        />
                    )}
                </SkeletonLoadingContent>
            </Section>
        </Grid>
    );
}
